import { useEffect, useState } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import NoTipsFound from '../components/tips/NoTipsFound';
import useHttp from '../components/hooks/use-http';
import { getAllTipCategories, getAllTips } from '../lib/api';
import TipList from '../components/tips/TipList';
import Card from '../components/UI/Card';
import {
  Select,
  Pagination as AntdPagination,
  SelectProps,
  Input,
  Button,
} from 'antd';
import './AllTips.scss';
import { Tip } from '../models/tip';
import { Paginated } from '../models/paginated';
const { Search } = Input;

const AllTips = () => {
  const [pageSize, setPageSize] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const {
    sendRequest,
    abortControllerRef,
    status,
    data: loadedTips,
    error,
  } = useHttp<Paginated<Tip>>(getAllTips);

  const {
    sendRequest: allCategoriesRequest,

    status: categoriesStatus,
    data: loadedCategories,
  } = useHttp(getAllTipCategories);

  useEffect(() => {
    allCategoriesRequest();
  }, [allCategoriesRequest]);

  useEffect(() => {
    sendRequest({
      categoryIds: selectedCategories.length === 0 ? null : selectedCategories,
      searchTerm: searchTerm,
      pageNumber: currentPage,
      pageSize: pageSize,
    });
    const abortController = abortControllerRef.current!;
    return () => abortController.abort();
  }, [
    sendRequest,
    selectedCategories,
    searchTerm,
    currentPage,
    pageSize,
    abortControllerRef,
  ]);

  const SelectCategoryHandler = (value: string[]) => {
    setSelectedCategories(value);
    setCurrentPage(1);
  };

  const onSearchHandler = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const clearFiltersHandler = () => {
    setSelectedCategories([]);
    setSearchTerm('');
    setCurrentPage(1);
  };

  if (
    status === 'pending' ||
    categoriesStatus === 'pending' ||
    !loadedCategories ||
    !loadedTips
  ) {
    return (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <p className="centered focused">{error}</p>;
  }
  let content = <TipList tips={loadedTips?.items} />;
  if (status === 'completed' && (!loadedTips || loadedTips.totalCount === 0)) {
    content = <NoTipsFound />;
  }

  const options: SelectProps['options'] = [];
  loadedCategories.map((category: any) =>
    options.push({
      label: category.name,
      value: category.id,
    }),
  );

  return (
    <Card id="categories-card">
      <div className="card-header">
        <Select
          mode="multiple"
          allowClear
          placeholder="Select Categories"
          onChange={SelectCategoryHandler}
          options={options}
          defaultValue={selectedCategories}
        ></Select>
        <Search
          placeholder="Search..."
          allowClear
          onSearch={onSearchHandler}
          defaultValue={searchTerm}
        />
        <Button onClick={clearFiltersHandler}>Clear</Button>
      </div>

      {content}
      <AntdPagination
        style={{ textAlign: 'center', paddingTop: '20px' }}
        total={loadedTips.totalCount}
        defaultCurrent={currentPage}
        pageSizeOptions={[4, 10, 20, 50]}
        pageSize={pageSize}
        showPrevNextJumpers
        showQuickJumper
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
      />
    </Card>
  );
};

export default AllTips;

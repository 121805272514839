import axios, { AxiosResponse } from 'axios';
import { Category, NewCategory } from '../models/category';
import { StatisticsPerCategory, StatisticsPerDay } from '../models/statistics';
import { NewTip, Tip, TipsParams, UpdateTip } from '../models/tip';
import { CreteToDoList, ToDoList, UpdateToDoList } from '../models/ToDo';
import { User, UserFormValues } from '../models/user';
import { Paginated } from '../models/paginated';
import invariant from 'tiny-invariant';

const API = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = API;

axios.interceptors.request.use(
  (config) => {
    config.headers!['Authorization'] =
      'Bearer ' + localStorage.getItem('tip-token');
    return config;
  },
  (error) => {
    Promise.reject(error);
    localStorage.removeItem('tip-token');
  },
);

// axios.interceptors.request.eject(authInterceptor);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string, signal: AbortSignal) =>
    axios.get<T>(url, { signal }).then(responseBody),
  post: <T>(url: string, body: {}, signal: AbortSignal) =>
    axios.post<T>(url, body, { signal }).then(responseBody),
  put: <T>(url: string, body: {}, signal: AbortSignal) =>
    axios.put<T>(url, body, { signal }).then(responseBody),
  del: <T>(url: string, signal: AbortSignal) =>
    axios.delete<T>(url, { signal }).then(responseBody),
};

export interface RequestFunctionParams<U> {
  requestData?: U;
  abortSignal: AbortSignal;
}

export async function getAllTips({
  requestData,
  abortSignal,
}: RequestFunctionParams<TipsParams>) {
  const searchParams = new URLSearchParams();
  if (requestData) {
    searchParams.append('pageNumber', requestData.pageNumber);
    searchParams.append('pageSize', requestData.pageSize);
    requestData.categoryIds?.map((id) =>
      searchParams.append('categoryIds', id),
    );
    searchParams.append('searchTerm', requestData.searchTerm);
  } else {
    searchParams.append('pageNumber', '4');
    searchParams.append('pageNumber', '1');
  }

  return requests.get<Paginated<Tip>>(
    `/tips?${searchParams.toString()}`,
    abortSignal,
  );
}

export async function getTip({
  requestData,
  abortSignal,
}: RequestFunctionParams<string>) {
  return requests.get<Tip>(`/tips/${requestData}`, abortSignal);
}

export async function addTip({
  requestData,
  abortSignal,
}: RequestFunctionParams<NewTip>) {
  invariant(requestData, 'requestData is required');
  return requests.post<string>(`/tips`, requestData, abortSignal);
}

export async function updateTip({
  requestData,
  abortSignal,
}: RequestFunctionParams<UpdateTip>) {
  invariant(requestData, 'requestData is required');
  return requests.put<void>(
    `/tips/${requestData.id}`,
    requestData,
    abortSignal,
  );
}

export async function deleteTip({
  requestData,
  abortSignal,
}: RequestFunctionParams<string>) {
  return requests.del<void>(`/tips/${requestData}`, abortSignal);
}

export async function getAllTipCategories({
  abortSignal,
}: RequestFunctionParams<undefined>) {
  return requests.get<Category[]>(`/Categories`, abortSignal);
}

export async function deleteCategory({
  requestData,
  abortSignal,
}: RequestFunctionParams<string>) {
  return requests.del<void>(`/Categories/${requestData}`, abortSignal);
}

export async function addCategory({
  requestData,
  abortSignal,
}: RequestFunctionParams<NewCategory>) {
  invariant(requestData, 'requestData is required');
  return requests.post<string>(`/Categories`, requestData, abortSignal);
}

export async function getStatisticsPerCategory({
  abortSignal,
}: RequestFunctionParams<undefined>) {
  return requests.get<StatisticsPerCategory[]>(
    `/Statistics/PerCategory`,
    abortSignal,
  );
}

export async function getStatisticsPerDay({
  abortSignal,
}: RequestFunctionParams<undefined>) {
  return requests.get<StatisticsPerDay[]>(`/Statistics/PerDay`, abortSignal);
}

export async function getMdFilesStructure({
  abortSignal,
}: RequestFunctionParams<undefined>) {
  return requests.get(`/Test`, abortSignal);
}
//to do lists
export async function getToDoLists({
  abortSignal,
}: RequestFunctionParams<undefined>) {
  return requests.get<ToDoList[]>(`/ToDoLists`, abortSignal);
}

//create
export async function createToDoList({
  requestData,
  abortSignal,
}: RequestFunctionParams<CreteToDoList>) {
  invariant(requestData, 'requestData is required');
  return requests.post<string>(`/ToDoLists`, requestData, abortSignal);
}
//update
export async function updateToDoList({
  requestData,
  abortSignal,
}: RequestFunctionParams<UpdateToDoList>) {
  invariant(requestData, 'requestData is required');
  return requests.post<string>(`/ToDoLists`, requestData, abortSignal);
}
//
export async function deleteToDoList({
  requestData,
  abortSignal,
}: RequestFunctionParams<string>) {
  return requests.del<void>(`/ToDoLists/${requestData}`, abortSignal);
}

//identity
export async function login({
  requestData,
  abortSignal,
}: RequestFunctionParams<UserFormValues>) {
  invariant(requestData, 'requestData is required');
  return requests.post<User>(`/Account/login`, requestData, abortSignal);
}

import { useNavigate, useParams } from 'react-router-dom';
import TipsForm from '../components/tips/TipsForm';
import { addTip, deleteTip, getTip, updateTip } from '../lib/api';
import useHttp from '../components/hooks/use-http';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import { NewTip, UpdateTip } from '../models/tip';

const EditTip = () => {
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const {
    sendRequest: addTipRequest,
    status: addTipStatus,
    error: addTipError,
  } = useHttp(addTip);

  const {
    sendRequest: getTipRequest,
    status: getTipStatus,
    data: loadedTip,
  } = useHttp(getTip);

  const {
    sendRequest: updateTipRequest,
    status: updateTipStatus,
    error: updateTipError,
  } = useHttp(updateTip);

  const {
    sendRequest: deleteTipRequest,
    status: deleteTipStatus,
    error: deleteTipError,
  } = useHttp(deleteTip);

  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      getTipRequest(params.id);
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [getTipRequest, params.id]);

  useEffect(() => {
    if (
      (addTipStatus === 'completed' && addTipError === null) ||
      (updateTipStatus === 'completed' && updateTipError === null) ||
      (deleteTipStatus === 'completed' && deleteTipError === null)
    ) {
      navigate('/tips');
    }
  }, [
    addTipStatus,
    addTipError,
    updateTipStatus,
    updateTipError,
    deleteTipStatus,
    deleteTipError,
    navigate,
  ]);

  const addTipHandler = (tipData: NewTip) => {
    addTipRequest(tipData);
  };
  const updateTipHandler = (tipData: UpdateTip) => {
    updateTipRequest(tipData);
  };
  const deleteTipHandler = (tipData: string) => {
    deleteTipRequest(tipData);
  };

  if (
    getTipStatus === 'pending' ||
    updateTipStatus === 'pending' ||
    deleteTipStatus === 'pending'
  ) {
    return (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <TipsForm
      isLoading={addTipStatus === 'pending'}
      tip={editMode ? loadedTip : null}
      editMode={editMode}
      onAddTip={addTipHandler}
      onUpdateTip={updateTipHandler}
      onDeleteTip={deleteTipHandler}
    />
  );
};

export default EditTip;

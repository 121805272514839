import { useEffect } from 'react';
import useHttp from '../hooks/use-http';

import { Tree } from 'antd';
import { getMdFilesStructure } from '../../lib/api';

const MarkdownAptd = () => {
  const {
    sendRequest: fileStructureRequest,
    status: fileStructureRequestStatus,

    data: loadedFileStructure,
  } = useHttp(getMdFilesStructure);

  useEffect(() => {
    fileStructureRequest();
  }, [fileStructureRequest]);

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
    if (info.node.isFolder) {
      console.log('isFolder');
    } else {
      console.log(info.node.key);
    }
  };

  if (
    fileStructureRequestStatus === 'pending' ||
    loadedFileStructure === null
  ) {
    return <p>Loading</p>;
  }
  return (
    <div>
      <Tree
        showLine={true}
        showIcon={true}
        onSelect={onSelect}
        treeData={loadedFileStructure}
      />
    </div>
  );
};

export default MarkdownAptd;

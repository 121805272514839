import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './store/auth-context';

//import "antd/dist/reset.css";

import './index.css';
import App from './App';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el!);

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </BrowserRouter>,
);

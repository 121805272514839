import { Divider, Drawer, Layout } from 'antd';
import { useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import './MainNavigation.scss';
import AppLogout from './LogOut';
import AppMenu from './AppMenu';
const { Header } = Layout;

const MainNavigation = () => {
  const [openSideMenu, setOpenSideMunu] = useState(false);
  const authCtx = useContext(AuthContext);

  return (
    <Header>
      <div className="logo">
        <span className="tracking-in-expand-fwd">My Tech Tips</span>
      </div>

      {authCtx.isLoggedIn && (
        <>
          <div className="header-menu">
            <AppMenu isHorizontal={true} />
            <AppLogout />
          </div>

          <div className="menu-icon">
            <MenuFoldOutlined onClick={() => setOpenSideMunu(true)} />
          </div>

          <Drawer
            className="side-menu"
            open={openSideMenu}
            onClose={() => setOpenSideMunu(false)}
            closeIcon={<MenuUnfoldOutlined />}
            closable={true}
          >
            <AppMenu isHorizontal={false} />
            <Divider style={{ borderColor: 'gray', margin: 0 }} />
            <AppLogout />
          </Drawer>
        </>
      )}
    </Header>
  );
};

export default MainNavigation;

import './Layout.scss';
import MainNavigation from './MainNavigation';
import { ToastContainer } from 'react-toastify';
import { Layout as AntdLayout } from 'antd';
import React from 'react';

import 'react-toastify/dist/ReactToastify.css';
const { Footer } = AntdLayout;
interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <AntdLayout>
      <MainNavigation />
      <main className="main">{props.children}</main>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Footer className="footer">
        Tech Design ©2022-2023 Created by Vasilis Xenos
      </Footer>
    </AntdLayout>
  );
};

export default Layout;

import './Card.scss';

interface LayoutProps {
  children?: React.ReactNode;
  id?: string;
}

const Card: React.FC<LayoutProps> = (props) => {
  return (
    <div id={props.id} className="card">
      {props.children}
    </div>
  );
};

export default Card;

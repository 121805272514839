import { useReducer, useCallback, useRef } from 'react';
import { toast } from 'react-toastify';
import { RequestFunctionParams } from '../../lib/api';

//https://fkhadra.github.io/react-toastify/introduction/
const errorToast = (errorMsg: string) =>
  toast.error(errorMsg, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
  });

interface HttpAction<T> {
  type: 'SUCCESS' | 'SEND' | 'ERROR';
  errorMessage?: string;
  responseData?: T | null;
}

interface HttpState<T> {
  data?: T | null;
  error?: string | null;
  status?: 'pending' | 'completed' | null;
}

function httpReducer<T>(
  state: HttpState<T>,
  action: HttpAction<T>,
): HttpState<T> {
  switch (action.type) {
    case 'SEND':
      return {
        data: null,
        error: null,
        status: 'pending',
      };

    case 'SUCCESS':
      return {
        data: action.responseData,
        error: null,
        status: 'completed',
      };

    case 'ERROR':
      return {
        data: null,
        error: action.errorMessage,
        status: 'completed',
      };

    default:
      return state;
  }
}

function useHttp<T, U = any>(
  requestFunction: (params: RequestFunctionParams<U>) => Promise<T>,
) {
  const [httpState, dispatch] = useReducer(httpReducer<T>, {
    status: null,
    data: null,
    error: null,
  });

  const abortControllerRef = useRef<AbortController>();

  const sendRequest = useCallback(
    async function (requestData?: U) {
      abortControllerRef.current = new AbortController();
      dispatch({ type: 'SEND' });
      try {
        const responseData = await requestFunction({
          requestData: requestData,
          abortSignal: abortControllerRef.current.signal,
        });
        dispatch({ type: 'SUCCESS', responseData });
      } catch (error: any) {
        if (abortControllerRef.current.signal.aborted) {
          return;
        }

        dispatch({
          type: 'ERROR',
          errorMessage: error.message || 'Something went wrong!',
        });
        errorToast(
          error.response?.data?.title ||
            error.response?.data?.message ||
            error.response?.statusText ||
            'Something went wrong!',
        );
      }
    },
    [requestFunction],
  );

  return {
    sendRequest,
    abortControllerRef,
    ...httpState,
  };
}

export default useHttp;

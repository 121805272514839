import { Empty, Space, Button } from 'antd';
import { Link } from 'react-router-dom';

import './NoTipsFound.scss';

const NoTipsFound = () => {
  // const navigate = useNavigate();
  return (
    <div className="no-tips">
      <Space direction="vertical" align="center" size={50}>
        <Empty description={'No Tips found!'} />

        <Button type="default" htmlType="button">
          {<Link to="/tip/new">Add a Tip</Link>}
        </Button>

        {/* <Button type="primary" onClick={()=>navigate("/tip/new")}>Add a Tip</Button> */}
      </Space>
    </div>
  );
};

export default NoTipsFound;

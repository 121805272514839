import { Form, Input, Modal } from 'antd';
import { ToDoList } from '../../models/ToDo';

interface ToDoListModalProps {
  open: boolean;
  formMode: 'create' | 'update';
  toDoList?: ToDoList | null;
  onSubmit: (values: ToDoList) => void;
  onCancel: () => void;
}

const ToDoListModal = ({
  open,
  formMode,
  toDoList,
  onSubmit: onSubmit,
  onCancel,
}: ToDoListModalProps) => {
  const [form] = Form.useForm();

  //  if(formMode==='update'){
  //    form.setFieldsValue(toDoList)
  //  }
  return (
    <Modal
      title="To Do List"
      okText={formMode === 'create' ? 'Create' : 'Update'}
      centered
      open={open}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please input the To do Title!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea showCount maxLength={300} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ToDoListModal;

import { Form, Input, Modal, Select } from 'antd';
import { ToDoItem, ToDoList } from '../../models/ToDo';

interface ToDoItemModalProps {
  open: boolean;
  formMode: 'create' | 'update';
  availableToDoLists: ToDoList[];
  onSubmit: (values: ToDoItem) => void;
  onCancel: () => void;
}

const ToDoItemModal = ({
  open,
  formMode,
  availableToDoLists,
  onSubmit: onSubmit,
  onCancel,
}: ToDoItemModalProps) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="To Do"
      okText={formMode === 'create' ? 'Create' : 'Update'}
      centered
      open={open}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please input the To do Title!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="toDoList" label="To Do List">
          <Select>
            {availableToDoLists.map((toDoList) => {
              return (
                <Select.Option key={toDoList.id} value={toDoList.id}>
                  {toDoList.title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item name="priority" label="Priority">
          <Select defaultValue="0">
            <Select.Option value="0">None</Select.Option>
            <Select.Option value="1">Low</Select.Option>
            <Select.Option value="2">Medium</Select.Option>
            <Select.Option value="3">High</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea showCount maxLength={300} />
        </Form.Item>

        <Form.Item name="reamarks" label="Remarks">
          <Input.TextArea showCount maxLength={300} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ToDoItemModal;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';

const AuthContext = React.createContext({
  token: '',
  displayName: '',
  username: '',
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(readSavedToken());
  const [displayName, setDisplayName] = useState('');
  const [username, setUsername] = useState('');

  const userIsLoggedIn = !!token;

  const loginHandler = async (data) => {
    setToken(data.token);
    setUsername(data.username);
    setDisplayName(data.displayName);
    localStorage.setItem('tip-token', data.token);
    navigate('/');
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem('tip-token');
  };

  const contextValue = {
    token: token,
    displayName: displayName,
    username: username,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

// export function useAuthContext() {
//     const context = React.useContext(AuthContext);

//     if (context === undefined) {
//       // handle
//       throw new Error("useAuthContext should be used within an AuthProvider.");
//     }
//     return context;
//   }

const isExpired = (token) => {
  var jwtTokenBase64 = token.split('.')[1];
  const jwtToken = JSON.parse(Buffer.from(jwtTokenBase64, 'base64'));
  const expires = new Date(jwtToken.exp * 1000);

  return expires.getTime() - Date.now() <= 0;
};

const readSavedToken = () => {
  const savedToken = localStorage.getItem('tip-token');
  if (!savedToken) {
    return '';
  }
  if (isExpired(savedToken)) {
    localStorage.removeItem('tip-token');
    return '';
  }
  return savedToken;
};

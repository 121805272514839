import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Card } from 'antd';
import './TipItem.scss';

const { Title } = Typography;

interface TipItemProps {
  title?: string;
  description?: string;
  category?: string;
  source?: string;
  id: string;
}
const TipItem = (props: TipItemProps) => {
  return (
    <Card
      className="item"
      title={<Title level={4}>{props.title}</Title>}
      extra={
        <Link className="edit" to={`../tip/${props.id}`}>
          <EditOutlined /> Edit
        </Link>
      }
    >
      <blockquote>{props.description}</blockquote>
      <figcaption> Category: {props.category}</figcaption>
      <figcaption> Source: {props.source} </figcaption>
    </Card>
  );
};

export default TipItem;

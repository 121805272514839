import TipItem from './TipItem';
import { Space } from 'antd';
import { Tip } from '../../models/tip';

interface TipListProps {
  tips: Tip[];
}

const TipList = (props: TipListProps) => {
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: 'flex',
      }}
    >
      {props.tips.map((tip: Tip) => (
        <TipItem
          id={tip.id}
          key={tip.id}
          title={tip.title}
          description={tip.description}
          category={tip.category.name}
          source={tip.source}
        />
      ))}
    </Space>
  );
};

export default TipList;

import CategoriesPie from '../components/Statistics/CategoriesPie';
import StatsPerDay from '../components/Statistics/StatsPerDay';

function Dashboard() {
  return (
    <div>
      <CategoriesPie />
      <StatsPerDay />
    </div>
  );
}

export default Dashboard;

import { LogoutOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import './Logout.scss';

const AppLogout = () => {
  const authCtx = useContext(AuthContext);
  return (
    <div className="logout" onClick={() => authCtx.logout()}>
      <LogoutOutlined />
      <span> Logout</span>
    </div>
  );
};

export default AppLogout;

import { Button, Form, Input } from 'antd';
import { login } from '../lib/api';
import useHttp from '../components/hooks/use-http';
import { useContext, useEffect } from 'react';
import AuthContext from '../store/auth-context';
import Card from '../components/UI/Card';
import { UserFormValues } from '../models/user';

const LonginAntd = () => {
  const authCtx = useContext(AuthContext);
  const {
    sendRequest: loginRequest,
    status: loginRequestStatus,
    data: loginData,
  } = useHttp(login);

  useEffect(() => {
    if (loginRequestStatus === 'completed' && loginData?.token) {
      authCtx.login(loginData);
    }
  }, [loginRequestStatus, authCtx, loginData]);

  const onFinish = (values: UserFormValues) => {
    loginRequest(values);
  };
  return (
    <div className="centered">
      <Card>
        <Form
          requiredMark={false}
          size="middle"
          name="basic"
          labelCol={{
            span: 10,
            offset: 0,
          }}
          wrapperCol={{
            span: 14,
          }}
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};
export default LonginAntd;

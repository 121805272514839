import { useState, useEffect } from 'react';
import { Pie } from '@ant-design/plots';
import useHttp from '../hooks/use-http';
import { getStatisticsPerCategory } from '../../lib/api';
import Card from '../UI/Card';

const CategoriesPie = () => {
  const [dataToProject, setData] = useState<{ type: string; value: number }[]>(
    [],
  );
  const {
    sendRequest,
    data: stats,
    status,
  } = useHttp(getStatisticsPerCategory);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  useEffect(() => {
    if (
      stats !== null &&
      stats !== undefined &&
      stats.length > 0 &&
      status === 'completed'
    ) {
      const data = stats.map((d: { categoryName: string; count: number }) => {
        return {
          type: d.categoryName,
          value: d.count,
        };
      });
      setData(data);
    }
  }, [stats, status]);

  const config = {
    appendPadding: 10,
    data: dataToProject,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    innerRadius: 0.5,
    label: {
      type: 'outer',
      content: '{name} : {value}',
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  if (!dataToProject) {
    return <p></p>;
  }
  return (
    <Card>
      <Pie {...config} />
    </Card>
  );
};

export default CategoriesPie;

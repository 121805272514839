import { Route, Routes, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from './store/auth-context';
import Layout from './components/layout/Layout';
import Markdown from './components/Markdown/Markdown';
import MarkdownAptd from './components/Markdown/MarkdownAptd';
import AllTips from './pages/AllTips';
import EditTip from './pages/EditTip';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import ToDo from './pages/ToDo';
import Dashboard from './pages/Dashboard';

const App = () => {
  const authCtx = useContext(AuthContext);

  return (
    <Layout>
      <Routes>
        {authCtx.isLoggedIn && (
          <>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/tips" element={<AllTips />} />
            <Route path="/tip/new" element={<EditTip />} />
            <Route path="/tip/:id" element={<EditTip />} />
            <Route path="/to-do" element={<ToDo />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/test-md" element={<Markdown />} />
            <Route path="/test-md-antd" element={<MarkdownAptd />} />
            <Route path="*" element={<NotFound />} />
          </>
        )}
        {!authCtx.isLoggedIn && (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </Layout>
  );
};
export default App;

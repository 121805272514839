import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import React from 'react';
//import classes from './Markdown.module.css'

const Markdown = () => {
  const [content, setContent] = useState('');
  useEffect(() => {
    fetch('../../MDs/Remote-login-ssh.md')
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, []);
  return (
    <div>
      {/* className={classes} */}
      <ReactMarkdown children={content} />
    </div>
  );
};

export default Markdown;

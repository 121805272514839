import {
  DeleteFilled,
  FileAddOutlined,
  FolderAddOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Divider, FloatButton, List, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useHttp from '../components/hooks/use-http';
import ToDoItemModal from '../components/ToDos/ToDoItemModal';
import ToDoListModal from '../components/ToDos/ToDoListModal';
import { getToDoLists, createToDoList, deleteToDoList } from '../lib/api';
import { ToDoItem, ToDoList } from '../models/ToDo';
import Draggable from 'react-draggable';
import './ToDo.scss';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const ColorList = ['#00a2ae', '#7265e6', '#ffbf00', '#f56a00'];
function ToDo() {
  const [toDoModalOpen, setToDoModalOpen] = useState(false);
  const [toDoListModalOpen, setToDoListModalOpen] = useState(false);

  //const [toDoLists ,setToDoLists] = useState<ToDoList[]>([]);

  const [initLoading, setInitLoading] = useState(true);

  const [toDoListModalMode, setToDoListModalMode] = useState<
    'create' | 'update'
  >('create');
  const [selectedList, setSelectedList] = useState<ToDoList | null>(null);

  const {
    sendRequest,
    abortControllerRef,
    status,
    data: loadedLists,
    error,
  } = useHttp(getToDoLists);

  const {
    sendRequest: createToDoListRequest,
    status: createToDoListStatus,
    error: createToDoListError,
  } = useHttp(createToDoList);

  const {
    sendRequest: deleteToDoListRequest,
    status: deleteToDoListStatus,
    error: deleteToDoListError,
  } = useHttp(deleteToDoList);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  const onSubmit = (values: ToDoItem) => {
    console.log('Received values of form: ', values);

    // const toDoList = toDoLists.find(x=>x.id === values.toDoList)
    //  if(toDoList?.toDoItems===null ||toDoList?.toDoItems=== undefined){
    //     toDoList!.toDoItems=[];
    //   }
    //  toDoList!.toDoItems = [...toDoList!.toDoItems , values]
    // setToDoLists((prev) =>[ ...prev.filter(x=>x.id !==toDoList?.id), toDoList!]);
    setToDoModalOpen(false);
  };

  const onListCreate = (values: any) => {
    createToDoListRequest(values);
    setToDoListModalOpen(false);
  };

  const deleteToDoListHandler = (id: string) => {
    deleteToDoListRequest(id);
  };

  const onToDoListEdit = (todolist: ToDoList) => {
    // setToDoListModalMode("update");
    // setToDoListModalOpen(true);
    // setSelectedList(todolist);
  };

  if (status === 'pending' || !loadedLists) {
    return (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <List
        grid={{ gutter: 0, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
        dataSource={loadedLists as ToDoList[]}
        // itemLayout="horizontal"
        renderItem={(list) => {
          return (
            <Draggable>
              <List.Item>
                <List
                  className="to-do-list"
                  header={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {list.title}
                      <div style={{ width: '83px' }}>
                        <Button
                          type="link"
                          onClick={() => onToDoListEdit(list)}
                        >
                          Edit
                        </Button>
                        <Divider type="vertical" />

                        <Popconfirm
                          placement="bottom"
                          icon={<DeleteFilled style={{ color: 'red' }} />}
                          title="Delete "
                          description="Are you sure you want to delete this list?"
                          onConfirm={() => deleteToDoListHandler(list.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link" danger>
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  }
                  footer={<div>Count: {list.toDoItems?.length ?? '0'}</div>}
                  bordered
                  dataSource={list.toDoItems}
                  renderItem={(item) => (
                    <List.Item
                      extra={
                        <div style={{ width: '75px' }}>
                          <Link to={`../tip/`}>Edit</Link>
                          <Divider type="vertical" />
                          <Link to={`../tip/`}>More</Link>
                        </div>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: ColorList[item?.priority],
                            }}
                            size="small"
                          />
                        }
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </List.Item>
            </Draggable>
          );
        }}
      />

      <ToDoItemModal
        open={toDoModalOpen}
        formMode="create"
        availableToDoLists={loadedLists as ToDoList[]}
        onSubmit={onSubmit}
        onCancel={() => {
          setToDoModalOpen(false);
        }}
      />

      <ToDoListModal
        open={toDoListModalOpen}
        formMode={toDoListModalMode}
        toDoList={selectedList}
        onSubmit={onListCreate}
        onCancel={() => {
          setToDoListModalOpen(false);
          setToDoListModalMode('create');
        }}
      />

      <FloatButton.Group
        icon={<OrderedListOutlined />}
        type="primary"
        trigger="click"
      >
        <FloatButton
          icon={<FileAddOutlined />}
          type="primary"
          tooltip={<div>Create To Do</div>}
          onClick={() => setToDoModalOpen(true)}
        />
        <FloatButton
          icon={<FolderAddOutlined />}
          tooltip={<div>Create To Do List</div>}
          onClick={() => setToDoListModalOpen(true)}
        />
      </FloatButton.Group>
    </>
  );
}

export default ToDo;

import {
  FileAddOutlined,
  ReadOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const AppMenu = ({ isHorizontal }: { isHorizontal: boolean }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const items = [
    { key: '/dashboard', label: 'Dashboard', icon: <ScheduleOutlined /> },
    { key: '/tips', label: 'All Tips', icon: <ReadOutlined /> },
    { key: '/tip/new', label: 'Add a Tip', icon: <FileAddOutlined /> },
    { key: '/to-do', label: 'To Do', icon: <ScheduleOutlined /> },

    // { key: "/test-md", label: "Test MD" },
    // { key: "/test-md-antd", label: "Test MD-antd" },
  ];

  return (
    <Menu
      theme="dark"
      mode={isHorizontal ? 'horizontal' : 'vertical'}
      defaultSelectedKeys={['/tips']}
      selectedKeys={[pathname]}
      style={{ flex: 1, alignItems: 'center' }}
      onClick={(e) => navigate(e.key)}
      items={items}
    />
  );
};

export default AppMenu;

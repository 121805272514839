import { useRef, useState, useEffect } from 'react';
import Card from '../UI/Card';
import CustomCategorySelect from './CustomCategorySelect';
import { Form, Input, Button, Space, Popconfirm } from 'antd';
import { NewTip, Tip, UpdateTip } from '../../models/tip';

interface TipsFormProps {
  isLoading: boolean;
  tip?: Tip | null;
  editMode: boolean;
  onUpdateTip: (values: UpdateTip) => void;
  onAddTip: (values: NewTip) => void;
  onDeleteTip: (values: string) => void;
}
const TipsForm = (props: TipsFormProps) => {
  const [initializing, setInitializing] = useState(true);
  const [addingCategory, setAddingCategory] = useState(false);
  const formRef = useRef<any>();

  const submitFormHandler = (values: {
    title: string;
    category: string;
    description: string;
    source: string;
  }) => {
    if (props.editMode) {
      props.onUpdateTip({
        id: props.tip!.id,
        title: values.title,
        categoryId: values.category,
        description: values.description,
        source: values.source,
      });
    } else {
      props.onAddTip({
        title: values.title,
        categoryId: values.category,
        description: values.description,
        source: values.source,
      });
    }
  };
  const deleteItemHandler = () => {
    props.onDeleteTip(props.tip!.id);
  };

  useEffect(() => {
    formRef.current.setFieldsValue({
      title: props.tip?.title ?? '',
      category: props.tip?.category.id ?? undefined,
      description: props.tip?.description ?? '',
      source: props.tip?.source ?? '',
    });
    setAddingCategory(false);
    setInitializing(false);
  }, [props]);

  return (
    <Card>
      <Form
        layout="vertical"
        ref={formRef}
        autoComplete="off"
        onFinish={submitFormHandler}
        style={{ paddingTop: '20px' }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please enter Title',
            },
            { whitespace: true, message: 'Empty Title field' },
            { min: 3 },
          ]}
        >
          <Input disabled={addingCategory} placeholder="Type Tip's Title" />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[
            {
              required: true,
              message: 'Please Select Category',
            },
          ]}
        >
          {/* Form.Item will pass onChange and values automatically */}
          {/* @ts-ignore */}
          <CustomCategorySelect
            setAddingCategory={setAddingCategory}
            addingCategory={addingCategory}
            initializing={initializing}
          />
        </Form.Item>

        <Form.Item name="source" label="Source">
          <Input disabled={addingCategory} placeholder="Type Tip's Source" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: 'Please enter Description',
            },
            { whitespace: true, message: 'Empty Description' },
            { min: 3 },
          ]}
        >
          <Input.TextArea disabled={addingCategory} rows={4} />
        </Form.Item>

        <Form.Item style={{ textAlign: 'right' }}>
          <Space size="middle">
            <Button
              disabled={addingCategory}
              type="primary"
              htmlType="submit"
              size="large"
            >
              {props.editMode ? 'Update Tip' : 'Add Tip'}
            </Button>
            {props.editMode && (
              <Popconfirm
                title="Delete"
                description="Are you sure to delete this Tip?"
                onConfirm={deleteItemHandler}
                okText="Yes"
                cancelText="No"
              >
                <Button danger type="primary" size="large">
                  Delete Tip
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default TipsForm;

import { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';
import useHttp from '../hooks/use-http';
import { getStatisticsPerDay } from '../../lib/api';
import Card from '../UI/Card';

const StatsPerDay = () => {
  const [dataToProject, setData] = useState<{ Date: string; Count: number }[]>(
    [],
  );
  const { sendRequest, data: stats, status } = useHttp(getStatisticsPerDay);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  function formatDate(date: Date) {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('-');
  }

  useEffect(() => {
    if (
      stats !== null &&
      stats !== undefined &&
      stats.length > 0 &&
      status === 'completed'
    ) {
      const data = stats.map((d) => {
        let date = new Date(d.date);
        return {
          Date: formatDate(date),
          Count: d.count,
        };
      });

      setData(data);
    }
  }, [stats, status]);

  const config = {
    theme: 'default', // 'dark',
    data: dataToProject,
    xField: 'Date',
    yField: 'Count',
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    brush: { enabled: true },
    slider: {
      start: 0,
      end: 1,
    },
  };
  if (dataToProject.length === 0 || dataToProject === null) {
    return <p></p>;
  }

  return (
    <Card>
      <Column {...config} />
    </Card>
  );
};

export default StatsPerDay;

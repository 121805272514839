import { useEffect, useState } from 'react';
import './CustomCategorySelect.scss';
import useHttp from '../hooks/use-http';
import {
  deleteCategory,
  addCategory,
  getAllTipCategories,
} from '../../lib/api';
import LoadingSpinner from '../UI/LoadingSpinner';
import { Button, Select, Input } from 'antd';
import { Category } from '../../models/category';

interface CustomCategorySelectProps {
  addingCategory: boolean;
  value?: string;
  initializing: boolean;
  setAddingCategory: (adding: boolean) => void;
  onChange: (value: string) => void;
}
const CustomCategorySelect = (props: CustomCategorySelectProps) => {
  const [categoriesMutated, setCategoriesMutated] = useState(true);
  const [newCategoryName, setNewCategoryName] = useState('');

  const {
    sendRequest: allCategoriesRequest,
    status: categoriesStatus,
    error: categoriesLoadError,
    data: loadedcategories,
  } = useHttp(getAllTipCategories);

  useEffect(() => {
    if (!props.initializing) {
      allCategoriesRequest();
    }
  }, [allCategoriesRequest, props.initializing]);

  const {
    sendRequest: addcategoryRequest,
    status: addcategoryStatus,
    data: newCategoryId,
  } = useHttp(addCategory);

  const { sendRequest: deleteCategoryRequest, status: deleteCategoryStatus } =
    useHttp(deleteCategory);

  useEffect(() => {
    if (
      (deleteCategoryStatus === 'completed' ||
        addcategoryStatus === 'completed') &&
      categoriesMutated === true
    ) {
      allCategoriesRequest();
      setCategoriesMutated(false);
      props.setAddingCategory(false);
    }
  }, [
    allCategoriesRequest,
    categoriesMutated,
    deleteCategoryStatus,
    addcategoryStatus,
    props,
    newCategoryId,
  ]);

  const deleteCategoryHandler = async () => {
    await deleteCategoryRequest(props.value);
    setCategoriesMutated(true);
  };

  const cancelAddingHandler = () => {
    props.setAddingCategory(false);
  };

  const newCategoryHandler = () => {
    props.setAddingCategory(true);
  };

  const addCategoryHandler = async () => {
    if (newCategoryName === '') {
      return;
    }
    await addcategoryRequest({
      name: newCategoryName,
    });
    setNewCategoryName('');
    setCategoriesMutated(true);
  };

  if (
    categoriesStatus === 'pending' ||
    addcategoryStatus === 'pending' ||
    deleteCategoryStatus === 'pending'
  ) {
    return (
      <div>
        <label htmlFor="category">Category</label>
        <div className="inline-centered">
          <LoadingSpinner small={true} />
        </div>
      </div>
    );
  }

  if (categoriesLoadError) {
    return <p className="centered focused">{categoriesLoadError}</p>;
  }

  if (props.addingCategory) {
    return (
      <div className="custom-category-select">
        <Input
          placeholder="New Category Name"
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <div className="custom-category-select__buttons">
          <Button onClick={addCategoryHandler} type="primary">
            Add Category
          </Button>
          <Button onClick={cancelAddingHandler}>Cancel</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="custom-category-select">
      <Select
        value={props.value}
        placeholder="Select Category"
        onChange={props.onChange}
      >
        {loadedcategories?.map((category: Category) => {
          return (
            <Select.Option key={category.id} value={category.id}>
              {category.name}
            </Select.Option>
          );
        })}
      </Select>
      <div className="custom-category-select__buttons">
        <Button onClick={newCategoryHandler}>New Category</Button>
        <Button danger onClick={deleteCategoryHandler}>
          Delete Selected
        </Button>
      </div>
    </div>
  );
};

export default CustomCategorySelect;
